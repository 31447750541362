//fonts
@font-face {
    font-family: 'AkzidenzGroteskBQ-BoldExt';
    src: url('../fonts/AkzidenzGroteskBQ-BoldExt.eot') format('opentype');
}
@font-face {
    font-family: 'AkzidenzGroteskBQ-BoldExt';
    src: url('../fonts/AkzidenzGroteskBQ-BoldExt.ttf') format('opentype');
}
@font-face {
    font-family: 'AkzidenzGroteskBQ-BoldExt';
    src: url('../fonts/AkzidenzGroteskBQ-BoldExt.woff') format('opentype');
}
@font-face {
    font-family: 'AkzidenzGroteskBQ-BoldExt';
    src: url('../fonts/AkzidenzGroteskBQ-BoldExt.woff2') format('opentype');
}


@font-face {
    font-family: 'AkzidenzGroteskBQ-LigExt';
    src: url('../fonts/AkzidenzGroteskBQ-LigExt.eot') format('opentype');
}
@font-face {
    font-family: 'AkzidenzGroteskBQ-LigExt';
    src: url('../fonts/AkzidenzGroteskBQ-LigExt.ttf') format('opentype');
}
@font-face {
    font-family: 'AkzidenzGroteskBQ-LigExt';
    src: url('../fonts/AkzidenzGroteskBQ-LigExt.woff') format('opentype');
}
@font-face {
    font-family: 'AkzidenzGroteskBQ-LigExt';
    src: url('../fonts/AkzidenzGroteskBQ-LigExt.woff2') format('opentype');
}


//variables
$grey-light:#A0AABB;
$grey-dark:#202020;
$main: #0062FF;
$second: #262626;

//

html, body{
    scroll-behavior: smooth;
}

.scroll-transition{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.2s , transform 0.5s;
    transition-timing-function: ease-in;
    
}

.scroll-transition.fade-in{
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.2s, transform 0.55s;
    transition-timing-function: ease-out;
    
}

.p-text-grey{
    color: $grey-light;
    font-weight: 450;
}
.p-text-grey-description{
    color: $grey-light;
    font-weight: 450;
    margin-top: 27px;
    text-align: center;
}

.buia-container{
    @extend .container;
}

.buia-btn-solid{
    background-color: $main;
    color: white;
    padding: 5px 20px;
    font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
    border-radius: 20px;
    margin: 5px 15px 15px 0;
    max-width: 250px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    img{
        width: 23px;
        margin-right: 5px;
    }
    i{
        margin-right: 8px;
    }
    &.white{
        background-color: white;
        color: $main;
    }
    &:hover{
        background-color: white;
        color: $grey-dark;
        background-color: #ffffffad;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
    }
   
}

.buia-btn-ghost{
    background-color: transparent;
    color: white;
    padding: 5px 20px;
    border: 1px solid white;
    border-radius: 10px;
    margin: 5px 15px 15px 0;
    max-width: 250px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    i{
        margin-right: 8px;
    }
    &.active{
        background-color: white;
        color: $main;
        //font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
        font-weight: 600;
    }
    &:hover{
        background-color: #ffffffad;
        color: $second;
        //font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
    }
}

.buia-btn-solid.white.mobile{
    display: none;
}

.buia-audiovisual-btn-group{
    margin: 10px 0;
    a{
        margin-top: 55px;
    }
    .buia-audiovisual-row-buttons{
        display: flex;
    }
}

//body
body{
    font-family: "AkzidenzGroteskBQ-LigExt", sans-serif;
    position: relative;

    h1{
        font-size: 42px;
        line-height: 53.13px;
        font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
    }

    h2{
       font-size: 35px;
       line-height: 37.95px;
       color: $grey-dark; 
    }

    h3{
        font-size: 24px;
        line-height: 30px;
        font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
        color: $grey-dark;
    }

    p{
        font-size: 18px;
        line-height: 22.77px;
        font-family: "AkzidenzGroteskBQ-LigExt", sans-serif;
        
    }
}

.atix-section{
    padding: 50px 0;
}
//navbar
.buia-navbar{
    z-index: 10;
    padding: 1rem;
}
.buia-logo-navbar{
    background-image: url('../images/logo-buia.svg');
    width: 100px;
    height: 100px;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: contain;
}

.buia-navbar-links{ 
    justify-content: flex-end;
    .nav-item{
        margin: 0 20px;
    }
}

.navbar-collapse.buia-navbar-links.collapse.show{
    margin-top: 50px;
}

.nav-item{
    a{
        font-size: 18px;
        line-height: 22.77px;
    }
}

//hero
.buia-hero-bg{
    background-image: url('../photos/buia-hero-bg-3.png');
    width: 100%;
    height: 100%;
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    border-radius: 0 0 140px 140px;
    position: relative;
    z-index: 1;
}
.buia-hero{
    padding-bottom: 300px;
    .row{
        margin-top: 80px;
    }
    .text{
        color: white;
        margin-top: 180px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        h1{
            font-size: 5rem;
        }
        p{
            font-size: 1.5rem;
            margin:25px 0 52px 0;
        }
        .hero-app-img{
            img{
                margin:0 15px 15px 0;
            }
        }
    }
    .graphic{
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .hero-shape{
            width: 180px;
            height: 180px;
           // background-color: white;
            background-color: #0350cd;
            transform: rotate(45deg);
            animation: rhombus-to-circle 0.7s cubic-bezier(0.39, 0.58, 0.57, 1) forwards, circle-animation 2s cubic-bezier(0.4, 0, 0.2, 1) infinite 0.75s;
            position: relative;
            transition: all 0.6s;
            -webkit-transition: all 0.6s;
            .content{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                //visibility: hidden;
                p{
                    cursor: pointer;
                    font-size: 0.9rem;
                    line-height: 20px;
                    font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
                    //color: $main;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 0;
                }
                #circleContent{
                    padding: 40px 20px;
                    &:hover{
                        filter: brightness(0.8);
                        transition: all 0.6s;
                        -webkit-transition: all 0.6s;
                    }
                }
               
                i, #circleIcon{
                    margin-bottom: 10px;
                    font-size: 20px;
                    //color: $main;
                    color: white;
                }
            }
            #videoText{
                cursor: pointer;
                font-size: 0.9rem;
                line-height: 15px;
                font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
                //color: $main;
                color: white;
                text-decoration: none;
                transition: all 0.4s;
                    -webkit-transition: all 0.4s;
                &:hover{
                    transform: scale(1.1);
                    transition: all 0.4s;
                    -webkit-transition: all 0.4s;
                }
            }
            #videoLink{
                cursor: pointer;
                font-size: 0.8rem;
                line-height: 20px;
                font-family: "AkzidenzGroteskBQ-BoldExt", sans-serif;
                //color: $main;
                color: white;
                text-decoration: none;
            }
            #circleIcon{
                display: none;
                margin-top: 30px;
                width: 30px;
                margin: 30px auto auto auto;
                position: relative;
                flex-direction: column;
                cursor: pointer;
                color: #dfe0e1!important;
                transition: all 0.6s;
                -webkit-transition: all 0.6s;
                &:hover{
                    transform: rotate(360deg);
                    transition: all 0.6s;
                    -webkit-transition: all 0.6s;
                }
            }
            #content-videos{
                margin-top: 40px;
                display: none;
            }
        }
    }
}

@keyframes rhombus-to-circle {
    50% {
      transform: rotate(225deg) scale(0.7);
      border-radius: 50%;
      transition: all 0.6s;
      -webkit-transition: all 0.6s;
    }
    100% {
      transform: rotate(360deg) scale(1);
      border-radius: 50%;
      transition: all 0.6s;
      -webkit-transition: all 0.6s;
    }
}

@keyframes circle-animation {
    0% {
      transform: scale(1);
      transition: all 0.6s;
      -webkit-transition: all 0.6s;
    }
    50% {
      transform: scale(1.1);
      transition: all 0.6s;
      -webkit-transition: all 0.6s;
    }
    100% {
      transform:  scale(1);
      transition: all 0.6s;
      -webkit-transition: all 0.6s;
    }
  }


//services buia
.buia-services {
    background-color: $second;
    margin-top: -140px;
    padding: 300px 20px 150px 20px;
    position: relative;
    h2{
        color: white;
        max-width: 350px;
        line-height: 3.5rem;
        font-size: 50px;
    }
    .left{
        display: flex;
        align-items: center;
        width: 45%;
        
        img{
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            transition: all 0.4s;
            -webkit-transition: all 0.4s;
        }
    }
    .right{
        display: flex;
        justify-content: center;
        width: 45%;
    }
    
}

.buia-card{
    background-color: $main;
    border-radius: 30px;
    padding: 30px 40px;
    color: white;
    min-width: 400px;
    max-width: 400px;
    z-index: 2;
    margin: 10px;
    cursor: pointer;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    h1{
        font-size: 30px;
        margin-top: 40px;
    }
    .buia-card_img{
        height: 150px;
        display: flex;
        justify-content: center;
        img{
            width: 150px;
            margin: auto;
            display: flex; 
            transition: all 0.4s;
            -webkit-transition: all 0.4s;
        }
        .tag{
            width: 210px;
            transition: all 0.4s;
            -webkit-transition: all 0.4s;
        }
    }
   
    a{
        position: relative;
        z-index: 1;
        text-decoration: none;
        color: white;
    }
    &:hover{
        background-color: #006ffd;
        transition: all 0.4s;
        -webkit-transition: all 0.4s;    
        .buia-card_img{
            transition: all 0.4s;
            -webkit-transition: all 0.4s;
            img{
                width: 170px;
                transition: all 0.4s;
                -webkit-transition: all 0.4s;
            }
            .tag{
                width: 230px;
                transition: all 0.4s;
                -webkit-transition: all 0.4s;
            }
        }
    }
}

//web
.buia-web{
    display: flex;
    .left{
        background-color: #424242;
        width: 40%;
        position: relative;
        .left-row{
            max-width: 50%;
            margin: auto;
            padding-top: 100px;
            h1{
                font-size: 6rem;
                color: $main;
                margin-bottom: 60px;
            }
            h2{
                font-family: 'AkzidenzGroteskBQ-BoldExt';
                color: white;
            }
            img{
                position: absolute;
                right: -60px;
                width: 300px;
                bottom: 100px;
                z-index: 0;
            }
        }
    }
    .right{
        background-color: $second;
        width: 60%;
        position: relative;
        color: white;
       
        .right-row{
            margin: auto;
            padding-top: 100px;
            max-width: 70%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            p{
                text-align: right;
                margin: 50px 0 50px 0;
                max-width: 600px;
            }
        }
        .right-row-bottom{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            max-width: 85%;
            margin: 150px 0 100px 0;
            p{
                font-family: 'AkzidenzGroteskBQ-BoldExt';
                font-size: 2rem; 
            }
        }
    }
}

//contact
.buia-contact{
    display: flex;
    background-color: $main;
    .container{
        padding: 150px 0 150px 0;
        .row{
            margin: 0;
        }
    }
    .left{
        width: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        h1{
            color: white;
        }
    }
    .right{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2{
            //font-family: 'AkzidenzGroteskBQ-BoldExt';
            color: white;
            font-size: 25px;
            margin-bottom: 25px;
        }
        .right-row{
            display: flex;
        }
    }
    
}

.contact-button{
    font-size: 20px;
    display: flex;
    width: 50px;
    height: 50px;
    transition: all .3s;
    color: white;
    border-radius: 100%;
    outline: none;
    background-color: $grey-dark;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    margin: 5px;
    &:hover{
        background-color: white;
        color: $main;
    }
}

//audiovisual buia
.buia-audiovisual{
    background-color: $main;
    color: white;
    padding: 100px 20px;
    position: relative;
    .buia-audiovisual_wrapper{
        display: flex;
        .left{
            width: 50%;
            padding-right: 10rem;
           .audiovisual-bg-element{
            position: absolute;
            top: 50px;
            right: 30px;
            width: 100px;
           }
        }
        .right{
            width: 50%;
            background-color: $main;
            margin: auto;
            display: flex;
            justify-content: center;
        }
    }
    .buia-audiovisual-nala{
        h1{
            font-size: 30px;
            line-height: 40px;
        }
        h2{
            font-size: 25px;
            font-family: "AkzidenzGroteskBQ-BoldExt",sans-serif;
            color: white;
            line-height: 20px;
        }
    }
    .left{
        
        .buia-description{
            margin-top: 50px;
            margin-bottom: 50px;
            max-width: 600px;    
        }
    }
    .right{
        background-color: grey;
        .buia-audiovisual-right-content{
            width: 600px;
            height: 400px;
            background-color: $grey-dark;
            position: relative;
            background-image: url('../photos/bg-audiovisual-right.png');
            background-position: center; 
            background-repeat: no-repeat; 
            background-size: cover;
            border-radius: 10px;
            iframe{
                border-radius: 10px;
            }
        }
    }
    .content{
        display: none;
    }
}

.btn-go-up{
    align-items: center;
    background-color: $grey-dark;
    border-radius: 20px;
    bottom: 50px;
    color: #fff;
    display: flex;
    font-size: 30px;
    padding: 5px 15px;
    justify-content: center;
    position: absolute;
    right: 40px;
    text-align: center;
    z-index: 1;
    text-decoration: none;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    &:hover{
        background-color: $grey-light;
        color: $grey-dark;
        transition: all 0.2s;
        -webkit-transition: all 0.2s;
    }
    p{
        margin-bottom: 0;
        margin-left: 10px;
    }
}

//media queries
@media (max-width: 1400px){
    .buia-audiovisual{
        padding: 0;
    }
}


@media (min-width: 1200px){
    .buia-container{
        max-width: 1500px;
    }
    .buia-card{
        margin: 10px 30px;
    }
    .buia-hero-bg{
        min-height: 800px;
    }
  
}

@media (max-width:1200px){
    .buia-audiovisual{
        .buia-audiovisual_wrapper{
            flex-direction: column;
            padding: 20px 20px 40px 20px;
            .left{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            .right{
                width: auto;
               
            }
        }
    }

    .buia-web .left{
        display: none;
    }
    .buia-web .right{
        width: 100%;
    }
    
}

@media (max-width: 1400px){


    .buia-services{
        padding: 200px 20px 150px 20px;
        h2{
            max-width: none;
            font-size: 35px;
            text-align: center;
        }
        .left{
           width: 100%;
           justify-content: center;
        }
        .right{
            width: 100%;
        }
       .buia-container .row{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .buia-audiovisual .left{
        padding: 100px;
    }

    .atix-download-bottom-wrapper{
        padding-bottom: 0;
        .download-left-side{
            order: 1;
        }
    }
    .atix-phones-bottom{
        display: none;
        img{
            display: none;
        }  
    }
    .atix-download-bottom-wrapper .download-left-side img{
        display: block;
        width: 100%;
    }
    .download-right-side{
        margin-bottom: 50px;
    }
    
}
@media (max-width: 992px) {
    .buia-hero{
        padding-bottom:100px;
        .text{
            margin-top:80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        .graphic{
            margin-top:50px;
            display: flex;
            justify-content: center;
            img{
                position: relative;
                max-width: 50%;
            }
        }
    }
    .buia-hero-bg{
        border-radius: 0 0 30px 30px;
    }

    .buia-card{
        padding: 20px 28px;
        min-width: 300px;
        max-width: 300px;
        .buia-card_img {
            img{
                width: 80px;
            }
            .tag{
                width: 110px;
            }
        }
        h1{
            font-size: 20px;
            margin-top: 0px;
        }
        p{
            font-size: 16px;
        }
        &:hover {
            .buia-card_img{
                img{
                    width: 100px;
                }
                .tag{
                    width: 130px;
                }
            }
        }
    }
   
}

@media (max-width: 690px) {
    body h1{
        font-size: 1.8rem;
        text-align: center;
    }
    .buia-audiovisual .left{
        padding: 20px 25px;
        .buia-description{
            line-height: 20px;
            margin-top: 0;
            //text-align: center;
            margin-bottom: 25px;
        }
    }
    .buia-audiovisual .right .buia-audiovisual-right-content{
        width: 350px;
        height: 250px;
    }
    .buia-audiovisual-btn-group{
        display: flex;
        flex-direction: column;
        //align-items: center;
        .buia-audiovisual-row-buttons{
            flex-wrap: wrap;
            display: flex;
            align-items: flex-start;
            .buia-btn-ghost{
                font-size: 0.9rem;
                min-width: auto;
                width: auto;
            }
        }
    }

    .buia-audiovisual .content{
        margin: 15px 20px 15px 0;
        font-style: italic;
    }
    .buia-hero .right-side img{
        max-width: 80%;
    }

    .buia-card{
        margin:25px auto;
    }

    .buia-services{
        padding: 200px 20px 90px 20px;
    }

    .buia-services .buia-container .row .right{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .buia-services .left img{
        display: none;
    }

    .buia-audiovisual-btn-group .buia-audiovisual-row-buttons{
        display: flex;
        //flex-direction: column;
    }

    .buia-btn-ghost {
        padding: 5px 8px;
        margin: 5px 8px 8px 0;
    }

    .buia-btn-solid.white.desktop{
        display: none;
    }

    .buia-btn-solid.white.mobile{
        display: flex;
    }
    
    .footer-center{
        display: flex;
        justify-content: center;
        text-align: center;
        p{
            margin-top: 30px;
        }
    }
    .buia-contact {
        .left{
            width: 100%;
            justify-content: center;
        }
        .right{
            width: 100%;
        }
    }
   
    .buia-audiovisual .buia-audiovisual_wrapper {
        padding: 40px 50px 100px 50px;
        h1{
            margin-bottom: 30px;
            margin-top: 30px;
            text-align: left;
        }
        .right{
            margin-left: 0;
            padding-left: 0;
            margin-bottom: 50px;
        }
    }
    .buia-audiovisual .buia-audiovisual_wrapper .left .audiovisual-bg-element{
        display: none;
    }
    .buia-web .right .right-row-bottom p{
        font-size: 1.7rem;
    }
}

@media (max-width: 400px){
    .buia-audiovisual .right .buia-audiovisual-right-content{
        width: 200px;
        height: 150px;
    }
}
