.nala-body{
    .navbar-dark .navbar-nav .nav-link{
        color: #4b4b4b;
        &:hover{
            color: black;
        }
    }
    .navbar-light .navbar-toggler{
        border-color: transparent;
    }
    .buia-hero{
        padding-bottom: 80px;
        .row{
            margin-top: -30px;
        }
    }

    .buia-hero-bg{
        background-image: url('../photos/nala-hero-bg-2.png');

    }
    .nala-hero-img{
        display: flex;
        justify-content: center;
        img{
            width: 500px;
        }
    }
    .nala-text-center{
        p{
            color: white;
        }
        .nala-sinopsis-text{
            margin: auto;
            h2{
                margin-bottom: 30px;
            } 
        }
    }
    .buia-services{
        padding-top: 200px;
        padding-bottom: 200px;
    }
    .nala-team{
        padding-top: 80px;
        background-color: #f4e1d2;
        .nala-sinopsis-text{
            display: flex;
            justify-content: center;
            h2{
                color: black;
            }
        }
        &.buia-services h2{
            max-width: none;
            margin-bottom: 30px;
        }
    }
    .nala-team-row{
        margin: auto;
        display: flex;
        justify-content: center;
        .buia-card{
            padding: 20px;
            min-width: 300px;
            max-width: 300px;
            margin-bottom: 30px;
            background-color: #d7944b;
            &:hover{
                background-color: #707952;
            }
        }
        .buia-card h1{
            font-size: 20px;
            margin-top: 5px;
            text-align: center;
            line-height: 20px;
        }
        .buia-card h2{
            font-size: 20px;
            line-height: 1px;
            margin-top: 30px;
            text-align: center;
            margin-bottom: 30px;
        }
        .team-icon{
            display: flex;
            justify-content: center;
            i, svg{
                font-size: 50px;
            }
        }
        .buia-services{
            padding-bottom: 100px;
        }
       
    }

    .buia-contact{
        background-color: #262626;
        .contact-button{
            background-color: #808080;
        }
    }
}